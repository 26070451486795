import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Leo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Leo Relationship
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/leo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Leo</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Leo Love</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Leo Relationship</h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-love");
                  }}>
                  <h5 className="font-semibold text-black">Leo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Leo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-man");
                  }}>
                  <h5 className="font-semibold text-black">Leo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Leo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Leo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Leo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
          <p className="playfair text-black text-xl md:text-3xl">Leo as a lover</p> <br></br>
          Leo, as a lover, is fearless and will reject no doubt to their loved one of their feelings. You do not play lead games and are incredibly honest about what you like and feel. You burn sunny and hot in a love relationship. For you, truthfulness is pivotal in forming the foundation of love. After committing to the lover, you are stubbornly faithful and will do anything to rescue and motivate your beloved. Your preference for beautiful things will insist you see your connection as a beautiful thing as well. However, you love to pamper your special with expensive dining, gifts, luxuries, and enjoyment. <br></br>
          Leo rules the heart, so you are warm, romantic, and dynamic. You tend to anticipate a lot from relationships. At times, you become possessive and envious. It is due to some insecurities you feel at the hands of your partner. <br></br>
          <br></br>
          Get insights into your love life with free love horoscope report! <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Leo as a colleague</p><br></br>
          You not only dream of reward and recognition but also outrightly demand it. You have a powerful personality and a requirement to be right in the workplace. You tend to make top-notch among your coworkers to be a supervisor of all. It is due to your warmness, gregariousness, benevolence, competence, and genuine leadership ability. You will courageously get the job done despite unfavorable circumstances quavering in your way. You hate gossip, politics in the workplace, and being influenced by others. <br></br>
          <br></br>
          Moreover, you demand power, command, respect, and appreciation of authenticity. You will help your colleagues and stick up for them. You are born leaders and are always ready to take on more duties/responsibilities. You may, at times, start treating your coworkers like your subordinates. Above this, you regularly need respect and admiration from everyone around you. <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Leo as a friend</p><br></br>
          You are highly in demand among your friends as you are passionate, generous, loyal, open-minded, and creative. You are friendly and always admire your friends and acquaintances. At the same time, you wish them to reciprocate similarly. You can make buddies very fast due to your fascinating and adorable personality. Your buddies are naturally drawn to you; many are glad to bask in your reflected light. <br></br> 
          <br></br>
          You smoothly hand out their number and are quick to follow people on social media. It isn't easy to know you at a deeper level. Though sociable, you won't share your internal emotional life until someone is very dear to you. It bothers me that you don't talk behind the back of anyone. You are sincere, straightforward, and occasionally blunt. You are a real friend who talks directly and usually gives genuine feedback.
          Every friendship is unique, after all. But according to astrology, some sun signs play better with others. So who are your best and worst friends? <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Leo as a boss</p><br></br>
          You are a born leader who can be magnanimous or authoritative. You will be honest and impartial. You wish your employees to work hard to impress you, honor you, and never ask your authority. You cause those who work hard and rarely be found secreting it out on your own. You have an inherent sense of people; hence empower work and duties based on their core strengths. <br></br>
          <br></br>
          However, you are a theatrical, self-confident, predominant, authoritative, and creative boss. You tend to give clear guidance, encouragement, and honest feedback on what's being done. You are highly active, have a clear direction for the long-term, and have a healthy sense of humor that can make cooperation easy. Above this, you are accommodating and give attractive rewards if your workers do a good job and reach desired results. You can't accept public resistance from your workers. Moreover, you expect absolute observance from them. <br></br>
          <br></br>
          To understand the pros and cons of your association with your boss, get a completely personalized and handwritten compatibility report for the boss and you. <br></br> 
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Leo fathers</p><br></br>
          You set yourself as an example of positiveness, bravery, elegance, benevolence, a caring attitude, and a perfect organizer for nursing your kids. You like to play with your children and expand their abilities. Even if you train them how to play different sports and motivate them for various activities from a young age. You keep them joyful and healthy and help them develop into great people. You rescue nature and save them from all kinds of danger. As you love your children dearly, you cannot see their shortcomings. When these flaws become apparent, you take them into your account, considering them your faults. You are likely to take an interest in what your kids care about. <br></br>
          <b></b>
          <p className="playfair text-black text-xl md:text-3xl">Leo mothers</p><br></br>
          Despite the prevailing note of power and authority, you love your kids with all your heart. You are strong, self-reliant, and unbeatable. You are adaptable and can completely design and organize your private life. At the same time, you successfully reach the career ladder. You are fierce for everyone but are softened up with your children. You buy everything for the kids, but you do not ignore yourself despite many responsibilities. Hence your child also fully coordinates to your level. You try to design your children to teach your kids to a place or excel in the area you grew up in. You will give them all the finer things to increase up with the talents and abilities to earn attractive things for themselves one day. You will not want to surpass your imperfections in them. In this situation, you will try to design the abilities of your kids. You will help them and boast of their accomplishments as their own. <br></br>
          <br></br>
          Every parent raises their children differently. <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Leo as a child</p><br></br>
          You are awarded many powers like warmth, commitment, willingness, affection, benevolence, and strong morals. Though you seem harsh from the outside, you are helpless, sensitive, and proud. You expect your parents to murmur with you; otherwise, it strikes your confidence. Your primary requirement is awareness and support, and you will do everything to gain the gratitude of others. You are proud and confident and unwilling to admit failure. You will not give up on items quickly. You have a genuine theatrical flair. You will treat others to spur-of-the-moment performances from a young age. You will keenly observe all the better things in life that improve your looks and character. You will gain a lot in life if you are glorified and given credit for the right items you do. <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Leo as a husband</p><br></br>
          You feel like a ruler and want to match the one who is a diva bee. You expect her to give you the attention and take sound care of your family. You are never nervous and less likely to step back from accepting responsibility. You tend to be mature from an earlier age and are protecting your wife and children. You are secure and have a solid conclusion to succeed in life. Above that, you have all it takes to become the best at whatever you decide to do. You desire your wife to take a back seat in your married life and permit you to take the lead. You are loving, devoted, and very supportive of your wife. You try to bring joy and positiveness to the table. Thus, this causes your married life easy, fun, and thrilling. You think of your house as a castle. You want to be cherished, pampered, and feasted like the ruler that you feel you are.
          Know your matrimony prospects and issues related to a wedding with the help of the best available astrology suggestion. <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Leo as a wife</p><br></br>
          All administration qualities like engaging, attention-grabbing, unique, secure, selfish, and pretentious are ingrained in you. You firmly build your strong image in the minds of others via your forceful voice, excellent pleasing nature, and intense sense. You leave your memorable impressions wherever you go. You will be the center of attention, no issue where you go. You will date multiple men but marry the one who lives up to your bars. You will be mindful that every small detail is set, but you can afford a honeymoon for one of the most exotic or romantic goals. You put all your period and effort into making your marriage fulfilling. However, you encourage your partner to follow your trust and dreams fully. You will not surrender yourself in married life completely. You don't like your spouse to control you all the time. You crave liberty and love to do things in your pathway and follow the directions and rules decided by you. <br></br>
          <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
